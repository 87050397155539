@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:opsz,wght@6..12,200&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:opsz,wght@6..12,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Tilt+Prism&display=swap');


.bg {

  background-repeat: no-repeat;
  background-size: auto 100vmin;

}


/* Add this to your CSS or use a CSS-in-JS solution like styled-components */
.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: hwb(0 0% 100% / 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.popup-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}




.icon-text {
  color: White;
  font-family: 'Nunito Sans', sans-serif;
  font-size: 3vh;
  display: block;
  line-height: 2vh;
  margin: 0 4px;
}



.heading {
  color: #fff;
  font-family: 'Nunito Sans', sans-serif;
  font-size: 4vmin;
  display: block;
  font-weight: 600;
  line-height: 1.1;

}

.logo {
  color: #fff;
  font-family: 'Tilt Prism', cursive;
  font-size: 4vmin;
  display: block;
  font-weight: 600;
  line-height: 1.1;

}

.descreption {
  color: #fff;
  font-family: 'Nunito Sans', sans-serif;
  font-weight: 400;
  font-size: 3vmin;
}

.appinfo {
  color: rgb(37, 50, 56, 0.9);
  font-family: 'Nunito Sans', sans-serif;
  font-size: 2vh;
  font-weight: 600;
  text-align: center;
  margin-top: 10px;
}



.masked {
  background-color: rgb(15, 21, 24, 0.4);
  cursor: not-allowed;
}

.popup-heading-one {
  color: #151d37;
  font-family: 'Nunito Sans', sans-serif;
  font-size: 4vh;
  display: block;
  line-height: 2vh;
}