/* CSS for the message pop-up */
.message-box {
  position: relative;
  background-color: #fff;
   /* Rounded border */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  color: #6d6d6dfb;
  font-family: 'Nunito Sans', sans-serif;
  /* Adjust the width as needed */
}

.message-box::before {
  content: '';
  position: absolute;
  top: -8px; /* Adjust the distance from the top as needed */
  right: 0px; /* Adjust the distance from the right as needed */
  border-width: 0 0 12px 12px; /* Create a triangular shape */
  border-style: solid;
  border-color: transparent transparent #fff transparent; /* Match the background color */
}

  
  .hideable-element {
    opacity: 0; /* Initially, hide the element */
    transition: opacity 0.5s ease-in-out; /* Add a smooth opacity transition */
  }
  
  .show {
    opacity: 1; /* Show the element by setting opacity to 1 */
  }